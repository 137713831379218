/*
@tailwind base;

@tailwind components;
*/

.bg-white {
  --bg-opacity: 1;
  background-color: #FFF;
  background-color: rgba(255, 255, 255, var(--bg-opacity))
}

.bg-orange-100 {
  --bg-opacity: 1;
  background-color: #FFE0B2;
  background-color: rgba(255, 224, 178, var(--bg-opacity))
}

.bg-green-100 {
  --bg-opacity: 1;
  background-color: #C8E6C9;
  background-color: rgba(200, 230, 201, var(--bg-opacity))
}

.bg-blue-100 {
  --bg-opacity: 1;
  background-color: #BBDEFB;
  background-color: rgba(187, 222, 251, var(--bg-opacity))
}

.hover\:bg-transparent:hover {
  background-color: transparent
}

.focus\:bg-transparent:focus {
  background-color: transparent
}

.bg-opacity-25 {
  --bg-opacity: 0.25
}

.border-gray-300 {
  --border-opacity: 1;
  border-color: #E0E0E0;
  border-color: rgba(224, 224, 224, var(--border-opacity))
}

.border-gray-400 {
  --border-opacity: 1;
  border-color: #BDBDBD;
  border-color: rgba(189, 189, 189, var(--border-opacity))
}

.border-orange-500 {
  --border-opacity: 1;
  border-color: #FF9800;
  border-color: rgba(255, 152, 0, var(--border-opacity))
}

.rounded-4 {
  border-radius: .4rem
}

.rounded-6 {
  border-radius: .6rem
}

.rounded-8 {
  border-radius: .8rem
}

.rounded-12 {
  border-radius: 1.2rem
}

.rounded-24 {
  border-radius: 2.4rem
}

.rounded-lg {
  border-radius: .8rem
}

.rounded-full {
  border-radius: 9999px
}

.border-b-1 {
  border-bottom-width: 1px
}

.border-l-4 {
  border-left-width: 4px
}

.border-t {
  border-top-width: 1px
}

.border-r {
  border-right-width: 1px
}

.cursor-default {
  cursor: default
}

.cursor-pointer {
  cursor: pointer
}

.block {
  display: block
}

.inline-block {
  display: inline-block
}

.inline {
  display: inline
}

.flex {
  display: flex
}

.table {
  display: table
}

.grid {
  display: grid
}

.hidden {
  display: none
}

.flex-row {
  flex-direction: row
}

.flex-row-reverse {
  flex-direction: row-reverse
}

.flex-col {
  flex-direction: column
}

.flex-wrap {
  flex-wrap: wrap
}

.items-start {
  align-items: flex-start
}

.items-center {
  align-items: center
}

.items-stretch {
  align-items: stretch
}

.justify-start {
  justify-content: flex-start
}

.justify-end {
  justify-content: flex-end
}

.justify-center {
  justify-content: center
}

.justify-between {
  justify-content: space-between
}

.flex-1 {
  flex: 1 1 0%
}

.flex-auto {
  flex: 1 1 auto
}

.flex-grow-0 {
  flex-grow: 0
}

.flex-grow {
  flex-grow: 1
}

.flex-shrink-0 {
  flex-shrink: 0
}

.flex-shrink {
  flex-shrink: 1
}

.order-last {
  order: 9999
}

.float-right {
  float: right
}

.font-400 {
  font-weight: 400
}

.font-500 {
  font-weight: 500
}

.font-700 {
  font-weight: 700
}

.font-800 {
  font-weight: 800
}

.font-thin {
  font-weight: 200
}

.font-light {
  font-weight: 300
}

.font-normal {
  font-weight: 400
}

.font-medium {
  font-weight: 500
}

.font-semibold {
  font-weight: 600
}

.font-bold {
  font-weight: 700
}

.h-8 {
  height: 0.8rem
}

.h-16 {
  height: 1.6rem
}

.h-20 {
  height: 2rem
}

.h-24 {
  height: 2.4rem
}

.h-40 {
  height: 4rem
}

.h-56 {
  height: 5.6rem
}

.h-76 {
  height: 7.6rem
}

.h-96 {
  height: 9.6rem
}

.h-full {
  height: 100%
}

.text-10 {
  font-size: 1rem
}

.text-11 {
  font-size: 1.1rem
}

.text-12 {
  font-size: 1.2rem
}

.text-13 {
  font-size: 1.3rem
}

.text-14 {
  font-size: 1.4rem
}

.text-16 {
  font-size: 1.6rem
}

.text-18 {
  font-size: 1.8rem
}

.text-20 {
  font-size: 2rem
}

.text-24 {
  font-size: 2.4rem
}

.text-44 {
  font-size: 4.4rem
}

.text-xs {
  font-size: 1.2rem
}

.text-sm {
  font-size: 1.4rem
}

.text-base {
  font-size: 1.6rem
}

.text-lg {
  font-size: 1.8rem
}

.text-xl {
  font-size: 2rem
}

.text-2xl {
  font-size: 2.4rem
}

.text-5xl {
  font-size: 4.8rem
}

.leading-tight {
  line-height: 1.25
}

.m-0 {
  margin: 0
}

.m-8 {
  margin: 0.8rem
}

.m-10 {
  margin: 1.0rem
}

.m-12 {
  margin: 1.2rem
}

.mx-4 {
  margin-left: 0.4rem;
  margin-right: 0.4rem
}

.my-8 {
  margin-top: 0.8rem;
  margin-bottom: 0.8rem
}

.mx-8 {
  margin-left: 0.8rem;
  margin-right: 0.8rem
}

.my-10 {
  margin-top: 1.0rem;
  margin-bottom: 1.0rem
}

.mx-10 {
  margin-left: 1.0rem;
  margin-right: 1.0rem
}

.my-12 {
  margin-top: 1.2rem;
  margin-bottom: 1.2rem
}

.my-16 {
  margin-top: 1.6rem;
  margin-bottom: 1.6rem
}

.mx-16 {
  margin-left: 1.6rem;
  margin-right: 1.6rem
}

.my-20 {
  margin-top: 2rem;
  margin-bottom: 2rem
}

.my-auto {
  margin-top: auto;
  margin-bottom: auto
}

.mx-auto {
  margin-left: auto;
  margin-right: auto
}

.-mx-8 {
  margin-left: -0.8rem;
  margin-right: -0.8rem
}

.-mx-12 {
  margin-left: -1.2rem;
  margin-right: -1.2rem
}

.mt-0 {
  margin-top: 0
}

.mr-2 {
  margin-right: 0.2rem
}

.mt-4 {
  margin-top: 0.4rem
}

.mr-4 {
  margin-right: 0.4rem
}

.mb-4 {
  margin-bottom: 0.4rem
}

.mt-6 {
  margin-top: 0.6rem
}

.mr-6 {
  margin-right: 0.6rem
}

.mb-6 {
  margin-bottom: 0.6rem
}

.ml-6 {
  margin-left: 0.6rem
}

.mt-8 {
  margin-top: 0.8rem
}

.mb-8 {
  margin-bottom: 0.8rem
}

.ml-8 {
  margin-left: 0.8rem
}

.mt-10 {
  margin-top: 1.0rem
}

.mr-10 {
  margin-right: 1.0rem
}

.mb-10 {
  margin-bottom: 1.0rem
}

.ml-10 {
  margin-left: 1.0rem
}

.mt-12 {
  margin-top: 1.2rem
}

.mb-12 {
  margin-bottom: 1.2rem
}

.ml-12 {
  margin-left: 1.2rem
}

.mt-16 {
  margin-top: 1.6rem
}

.mr-16 {
  margin-right: 1.6rem
}

.mb-16 {
  margin-bottom: 1.6rem
}

.ml-16 {
  margin-left: 1.6rem
}

.mt-20 {
  margin-top: 2rem
}

.mr-20 {
  margin-right: 2rem
}

.mb-20 {
  margin-bottom: 2rem
}

.ml-20 {
  margin-left: 2rem
}

.mt-24 {
  margin-top: 2.4rem
}

.mb-28 {
  margin-bottom: 2.8rem
}

.mt-32 {
  margin-top: 3.2rem
}

.mb-32 {
  margin-bottom: 3.2rem
}

.mt-48 {
  margin-top: 4.8rem
}

.mb-52 {
  margin-bottom: 5.2rem
}

.mb-72 {
  margin-bottom: 7.2rem
}

.mt-auto {
  margin-top: auto
}

.ml-auto {
  margin-left: auto
}

.-mt-2 {
  margin-top: -0.2rem
}

.-mt-4 {
  margin-top: -0.4rem
}

.-mr-4 {
  margin-right: -0.4rem
}

.-mt-6 {
  margin-top: -0.6rem
}

.-mb-8 {
  margin-bottom: -0.8rem
}

.-ml-8 {
  margin-left: -0.8rem
}

.-mt-10 {
  margin-top: -1rem
}

.-mb-10 {
  margin-bottom: -1rem
}

.-mr-12 {
  margin-right: -1.2rem
}

.-mr-16 {
  margin-right: -1.6rem
}

.-mt-24 {
  margin-top: -2.4rem
}

.-mb-32 {
  margin-bottom: -3.2rem
}

.-mr-36 {
  margin-right: -3.6rem
}

[dir='ltr'] .ltr\:ml-4,[dir='ltr'].ltr\:ml-4 {
  margin-left: 0.4rem
}

[dir='ltr'] .ltr\:ml-8,[dir='ltr'].ltr\:ml-8 {
  margin-left: 0.8rem
}

[dir='ltr'] .ltr\:mr-48,[dir='ltr'].ltr\:mr-48 {
  margin-right: 4.8rem
}

[dir='rtl'] .rtl\:mr-4,[dir='rtl'].rtl\:mr-4 {
  margin-right: 0.4rem
}

[dir='rtl'] .rtl\:mr-8,[dir='rtl'].rtl\:mr-8 {
  margin-right: 0.8rem
}

[dir='rtl'] .rtl\:ml-48,[dir='rtl'].rtl\:ml-48 {
  margin-left: 4.8rem
}

.max-w-320 {
  max-width: 32rem
}

.max-w-400 {
  max-width: 40rem
}

.max-w-sm {
  max-width: 48rem
}

.max-w-md {
  max-width: 64rem
}

.max-w-xl {
  max-width: 96rem
}

.max-w-full {
  max-width: 100%
}

.min-h-76 {
  min-height: 7.6rem
}

.min-h-full {
  min-height: 100%
}

.min-w-0 {
  min-width: 0
}

.min-w-40 {
  min-width: 4rem
}

.min-w-64 {
  min-width: 6.4rem
}

.opacity-50 {
  opacity: 0.5
}

.opacity-75 {
  opacity: 0.75
}

.overflow-auto {
  overflow: auto
}

.overflow-hidden {
  overflow: hidden
}

.p-0 {
  padding: 0
}

.p-2 {
  padding: 0.2rem
}

.p-8 {
  padding: 0.8rem
}

.p-12 {
  padding: 1.2rem
}

.p-16 {
  padding: 1.6rem
}

.p-24 {
  padding: 2.4rem
}

.p-64 {
  padding: 6.4rem
}

.py-0 {
  padding-top: 0;
  padding-bottom: 0
}

.px-0 {
  padding-left: 0;
  padding-right: 0
}

.py-2 {
  padding-top: 0.2rem;
  padding-bottom: 0.2rem
}

.py-4 {
  padding-top: 0.4rem;
  padding-bottom: 0.4rem
}

.py-6 {
  padding-top: 0.6rem;
  padding-bottom: 0.6rem
}

.py-8 {
  padding-top: 0.8rem;
  padding-bottom: 0.8rem
}

.px-8 {
  padding-left: 0.8rem;
  padding-right: 0.8rem
}

.px-10 {
  padding-left: 1.0rem;
  padding-right: 1.0rem
}

.px-12 {
  padding-left: 1.2rem;
  padding-right: 1.2rem
}

.py-16 {
  padding-top: 1.6rem;
  padding-bottom: 1.6rem
}

.px-16 {
  padding-left: 1.6rem;
  padding-right: 1.6rem
}

.py-20 {
  padding-top: 2rem;
  padding-bottom: 2rem
}

.px-20 {
  padding-left: 2rem;
  padding-right: 2rem
}

.px-24 {
  padding-left: 2.4rem;
  padding-right: 2.4rem
}

.py-96 {
  padding-top: 9.6rem;
  padding-bottom: 9.6rem
}

.pl-0 {
  padding-left: 0
}

.pr-2 {
  padding-right: 0.2rem
}

.pt-4 {
  padding-top: 0.4rem
}

.pb-4 {
  padding-bottom: 0.4rem
}

.pr-6 {
  padding-right: 0.6rem
}

.pt-8 {
  padding-top: 0.8rem
}

.pb-8 {
  padding-bottom: 0.8rem
}

.pl-8 {
  padding-left: 0.8rem
}

.pt-10 {
  padding-top: 1.0rem
}

.pt-12 {
  padding-top: 1.2rem
}

.pb-12 {
  padding-bottom: 1.2rem
}

.pb-16 {
  padding-bottom: 1.6rem
}

.pl-16 {
  padding-left: 1.6rem
}

.pl-20 {
  padding-left: 2rem
}

.pt-24 {
  padding-top: 2.4rem
}

.pb-24 {
  padding-bottom: 2.4rem
}

.pl-24 {
  padding-left: 2.4rem
}

.pr-28 {
  padding-right: 2.8rem
}

.pl-40 {
  padding-left: 4rem
}

.pl-56 {
  padding-left: 5.6rem
}

.pb-64 {
  padding-bottom: 6.4rem
}

.pl-72 {
  padding-left: 7.2rem
}

.pl-80 {
  padding-left: 8rem
}

.static {
  position: static
}

.fixed {
  position: fixed
}

.absolute {
  position: absolute
}

.relative {
  position: relative
}

.sticky {
  position: -webkit-sticky;
  position: sticky
}

.top-0 {
  top: 0
}

.right-0 {
  right: 0
}

.bottom-0 {
  bottom: 0
}

.left-0 {
  left: 0
}

[dir='ltr'] .ltr\:right-0,[dir='ltr'].ltr\:right-0 {
  right: 0
}

[dir='rtl'] .rtl\:left-0,[dir='rtl'].rtl\:left-0 {
  left: 0
}

.shadow-1 {
  box-shadow: 0px 1px 3px 0px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 2px 1px -1px rgba(0,0,0,0.12)
}

.shadow-2xl {
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25)
}

.hover\:shadow-2:hover {
  box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 3px 1px -2px rgba(0,0,0,0.12)
}

.text-left {
  text-align: left
}

.text-center {
  text-align: center
}

.text-right {
  text-align: right
}

.text-gray-400 {
  --text-opacity: 1;
  color: #BDBDBD;
  color: rgba(189, 189, 189, var(--text-opacity))
}

.text-gray-500 {
  --text-opacity: 1;
  color: #9E9E9E;
  color: rgba(158, 158, 158, var(--text-opacity))
}

.text-gray-600 {
  --text-opacity: 1;
  color: #757575;
  color: rgba(117, 117, 117, var(--text-opacity))
}

.text-gray-700 {
  --text-opacity: 1;
  color: #616161;
  color: rgba(97, 97, 97, var(--text-opacity))
}

.text-red-400 {
  --text-opacity: 1;
  color: #EF5350;
  color: rgba(239, 83, 80, var(--text-opacity))
}

.text-orange-600 {
  --text-opacity: 1;
  color: #FB8C00;
  color: rgba(251, 140, 0, var(--text-opacity))
}

.text-orange-900 {
  --text-opacity: 1;
  color: #E65100;
  color: rgba(230, 81, 0, var(--text-opacity))
}

.text-green-600 {
  --text-opacity: 1;
  color: #43A047;
  color: rgba(67, 160, 71, var(--text-opacity))
}

.text-green-900 {
  --text-opacity: 1;
  color: #1B5E20;
  color: rgba(27, 94, 32, var(--text-opacity))
}

.text-blue-600 {
  --text-opacity: 1;
  color: #1E88E5;
  color: rgba(30, 136, 229, var(--text-opacity))
}

.text-blue-900 {
  --text-opacity: 1;
  color: #0D47A1;
  color: rgba(13, 71, 161, var(--text-opacity))
}

.italic {
  font-style: italic
}

.uppercase {
  text-transform: uppercase
}

.capitalize {
  text-transform: capitalize
}

.normal-case {
  text-transform: none
}

.underline {
  text-decoration: underline
}

.align-middle {
  vertical-align: middle
}

.align-bottom {
  vertical-align: bottom
}

.visible {
  visibility: visible
}

.whitespace-no-wrap {
  white-space: nowrap
}

.whitespace-pre-line {
  white-space: pre-line
}

.whitespace-pre-wrap {
  white-space: pre-wrap
}

.break-words {
  word-wrap: break-word;
  overflow-wrap: break-word
}

.w-16 {
  width: 1.6rem
}

.w-20 {
  width: 2rem
}

.w-40 {
  width: 4rem
}

.w-48 {
  width: 4.8rem
}

.w-96 {
  width: 9.6rem
}

.w-xs {
  width: 32rem
}

.w-1\/2 {
  width: 50%
}

.w-1\/4 {
  width: 25%
}

.w-full {
  width: 100%
}

.z-0 {
  z-index: 0
}

.z-10 {
  z-index: 10
}

.z-999 {
  z-index: 999
}

.transform {
  --transform-translate-x: 0;
  --transform-translate-y: 0;
  --transform-rotate: 0;
  --transform-skew-x: 0;
  --transform-skew-y: 0;
  --transform-scale-x: 1;
  --transform-scale-y: 1;
  -webkit-transform: translateX(var(--transform-translate-x)) translateY(var(--transform-translate-y)) rotate(var(--transform-rotate)) skewX(var(--transform-skew-x)) skewY(var(--transform-skew-y)) scaleX(var(--transform-scale-x)) scaleY(var(--transform-scale-y));
          transform: translateX(var(--transform-translate-x)) translateY(var(--transform-translate-y)) rotate(var(--transform-rotate)) skewX(var(--transform-skew-x)) skewY(var(--transform-skew-y)) scaleX(var(--transform-scale-x)) scaleY(var(--transform-scale-y))
}

.transition {
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, -webkit-transform;
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform;
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, -webkit-transform
}

@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg)
  }
}

@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg)
  }
}

@keyframes ping {
  75%, 100% {
    -webkit-transform: scale(2);
            transform: scale(2);
    opacity: 0
  }
}

@keyframes pulse {
  50% {
    opacity: .5
  }
}

@keyframes bounce {
  0%, 100% {
    -webkit-transform: translateY(-25%);
            transform: translateY(-25%);
    -webkit-animation-timing-function: cubic-bezier(0.8,0,1,1);
            animation-timing-function: cubic-bezier(0.8,0,1,1)
  }

  50% {
    -webkit-transform: none;
            transform: none;
    -webkit-animation-timing-function: cubic-bezier(0,0,0.2,1);
            animation-timing-function: cubic-bezier(0,0,0.2,1)
  }
}

@media (min-width: 600px) {

  .sm\:font-light {
    font-weight: 300
  }

  .sm\:text-xl {
    font-size: 2rem
  }

  .sm\:ml-32 {
    margin-left: 3.2rem
  }

  .sm\:w-128 {
    width: 12.8rem
  }
}

@media (min-width: 960px) {

  .md\:flex {
    display: flex
  }

  .md\:m-24 {
    margin: 2.4rem
  }

  .md\:mx-auto {
    margin-left: auto;
    margin-right: auto
  }

  .md\:mt-0 {
    margin-top: 0
  }

  .md\:mb-24 {
    margin-bottom: 2.4rem
  }

  .md\:max-w-3xl {
    max-width: 128rem
  }

  .md\:p-0 {
    padding: 0
  }

  .md\:p-24 {
    padding: 2.4rem
  }

  .md\:px-24 {
    padding-left: 2.4rem;
    padding-right: 2.4rem
  }
}

@media (min-width: 1280px) {

  .lg\:px-24 {
    padding-left: 2.4rem;
    padding-right: 2.4rem
  }

  .lg\:pr-0 {
    padding-right: 0
  }

  [dir='ltr'] .lg\:ltr\:pl-0,[dir='ltr'].lg\:ltr\:pl-0 {
    padding-left: 0
  }

  [dir='rtl'] .lg\:rtl\:pr-0,[dir='rtl'].lg\:rtl\:pr-0 {
    padding-right: 0
  }
}
